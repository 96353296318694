import { GATSBY_ENV } from "gatsby-env-variables"
import { capitalize } from "../../../services/general"
import { sendToZendesk } from "../../../services/zendeskService"

import { parseZendeskField } from "./zendeskField"
import { handleError } from "../../../services/handleError"
import {
  GATSBY_SUBDOMAIN,
  GATSBY_DOCTOR_ENROLLMENT_FIELD,
} from "gatsby-env-variables"
import {
  doctorEnrollmentTicketTemplate,
  patientEnrollmentTicketTemplate,
} from "../utils/enrollmentTicketTemplate"

import { encrypt } from "../../../services/crypto"
import { zendeskApi } from "../../../services/zendesk"
import { generateRandomString } from "../../../services/general"

export const sendEnrollmentFormToZendesk = config => {
  let { enrollmentData, documents, ticketTemplate, type } = config // Removed file array
  let isTest = GATSBY_ENV !== "production"

  let tags = [type, "bm_talktodoc"]
  if (isTest) tags.push("test")

  let fullName =
    type === "doctor_enrollment"
      ? `${enrollmentData?.name}`
      : `${enrollmentData?.firstName} ${enrollmentData?.lastName}`

  if (
    enrollmentData?.preferredLaboratory?.value ===
    "Hi-Precision Diagnostics Centre"
  )
    tags.push("hp_lab")
  else tags.push("non_hp_lab")

  let subject = `${isTest ? "[TEST] " : ""}${capitalize(
    type.replace("_", " ")
  )} Form of ${fullName}`

  delete enrollmentData?.documents

  let requestBody = {
    type: "request",
    tags,
    subject,
    requester: {
      name: `${enrollmentData?.firstName} ${enrollmentData?.lastName}`,
      email: enrollmentData?.doctorEmail || enrollmentData?.emailAddress || "kumustadok@medgrocer.com",
    },
    custom_fields: [
      {
        id: GATSBY_DOCTOR_ENROLLMENT_FIELD,
        value: JSON.stringify({ formData: parseZendeskField(enrollmentData) }),
      },
    ],
    comment: { body: ticketTemplate({ enrollmentData }) },
  }

  return sendToZendesk(requestBody || {}, documents || [])
}

export const handleUserEnroll = async ({
  type,
  enrollmentData,
  documents,
  callback,
  errorCallback,
}) => {
  try {
    let response = await sendEnrollmentFormToZendesk({
      enrollmentData,
      documents,
      ticketTemplate:
        type === "doctor_enrollment"
          ? doctorEnrollmentTicketTemplate
          : patientEnrollmentTicketTemplate,
      type: type,
    })

    let responseId = response?.data?.request?.id.toString()
    let encryptedResponseID = encrypt({
      data: responseId,
    })

    let uniquePatientURL = `${GATSBY_SUBDOMAIN}/patient/diagnostic-subsidy?e=${encodeURIComponent(
      encryptedResponseID
    )}`

    enrollmentData.loaURL = uniquePatientURL
    enrollmentData.referenceCode = `${responseId}${generateRandomString({
      length: 3,
      inclusions: ["upper"],
    })}`
    zendeskApi().put(`/tickets/${responseId}`, {
      ticket: {
        custom_fields: [
          {
            id: GATSBY_DOCTOR_ENROLLMENT_FIELD,
            value: JSON.stringify({
              formData: parseZendeskField(enrollmentData),
            }),
          },
        ],
      },
    })

    if (callback) callback()
  } catch (error) {
    let errorMessage =
      "There was an error sending your enrollment. Please try again."
    handleError({ error, errorCallback, errorMessage })
  }
}
