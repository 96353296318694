import { useStaticQuery, graphql } from "gatsby"

export const useHookImages = () => {
  const data = useStaticQuery(graphql`
    {
      hpLogo: file(relativePath: { eq: "icons/hp-logo-png.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      phcLogo: file(relativePath: { eq: "icons/phc-logo-png.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      lcpLogo: file(relativePath: { eq: "icons/LCP_Logo.jpeg" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}
