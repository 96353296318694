export const parseZendeskField = enrollmentData => {
  if (enrollmentData?.specialization) {
    enrollmentData.parsedSpecialization =
      enrollmentData.specialization.value !== "Other (please specify)"
        ? enrollmentData.specialization.value
        : enrollmentData.otherSpecialization
  }

  if (enrollmentData?.specialization) {
    enrollmentData.parsedHospitalClinic =
      enrollmentData.hospitalClinic.value !== "Other"
        ? enrollmentData.hospitalClinic.value
        : enrollmentData.otherHospital
  }

  if (enrollmentData?.hospitalClinic) {
    enrollmentData.parsedDepartment =
      enrollmentData.department.value !== "Other (please specify)"
        ? enrollmentData.department.value
        : enrollmentData.otherDepartment
  }

  enrollmentData.parsedReachedVia = enrollmentData?.reachedVia?.join(", ")

  if (enrollmentData?.wouldLikeToReceiveUpdates) {
    enrollmentData.parsedUpdates =
      enrollmentData.wouldLikeToReceiveUpdates.length > 0 ? "Yes" : "No"
    delete enrollmentData.wouldLikeToReceiveUpdates
  }

  if (enrollmentData?.preferredLaboratory) {
    enrollmentData.parsedLaboratory = enrollmentData?.preferredLaboratory?.value
  }

  if (enrollmentData?.birthday) {
    let { month, date, year } = enrollmentData?.birthday

    enrollmentData.parsedBirthday = `${month.value} ${date.value} ${year}`
  }

  if (enrollmentData?.preferredAppointmentDate) {
    let { month, date, year } = enrollmentData?.preferredAppointmentDate

    enrollmentData.parsedPreferredAppointmentDate = `${month.value} ${date.value} ${year}`
  }

  if (enrollmentData?.followUpConsultation) {
    let { month, date, year } = enrollmentData?.followUpConsultation

    enrollmentData.parsedFollowupConsultation = `${month.value} ${date.value} ${year}`
  }

  if (enrollmentData?.availHomePackage) {
    enrollmentData.parsedHomePackage =
      enrollmentData?.availHomePackage?.length > 0 ? "YES" : "NO"
  }

  if (
    enrollmentData?.preferredLaboratory?.value ===
    "Philippine Heart Center (PHC)"
  ) {
    enrollmentData.homeAddress = {
      streetAddress: "N/A",
      city: { value: "N/A" },
      province: { value: "N/A" },
      province: { value: "N/A" },
    }
  }

  return enrollmentData
}
