export const defaultValues = ["", "null null ", "Metro Manila, ", undefined]

export const parseSummaryValue = (data, type) => {
  switch (type) {
    case "text":
      return data
    case "array":
      return data?.join(", ")
    case "date":
      return `${data?.month.value} ${data?.date.value} ${data?.year}`
    case "updates":
      return data?.length > 0 ? "Yes" : "No"
    case "address":
      let addressList = ""

      addressList = `${data?.addressType ? `${data?.addressType}, ` : ""}${
        data?.streetAddress ? `${data?.streetAddress}, ` : ""
      }${data?.province?.value ? `${data?.province?.value}, ` : ""}
      ${data?.city?.value ? `${data?.city?.value}` : ""}
      ${data?.notes ? `, ${data?.notes}` : ""}`

      return addressList
    default:
      return null
  }
}
