import React, { useContext, useState, useEffect } from "react"
import classNames from "classnames"
import { navigate } from "gatsby"
import { get } from "lodash"

import Layout from "layout/Layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import FormAddons from "./EnrollmentAddons"
import ActionButtons from "elements/ActionButtons"
import EditDetailsButton from "elements/EditDetailsButton"
import PrescriptionDetails from "elements/PrescriptionDetails"

import {
  GASTBY_DOCTOR_ENCRYPTED_KEY,
  GATSBY_DOCTOR_ENCRYPTED_VALUE,
} from "gatsby-env-variables"

import styles from "./utils/enrollment.module.scss"
import { AppContext } from "../../context/AppContext"
import { RESET_STATE } from "../../context/AppReducer"
import { handleUserEnroll } from "./services/enrollment"
import { parseSummaryValue, defaultValues } from "./services/summary"

const Summary = props => {
  const { backPath, nextPath, module, summaryConfig } = props.pageContext
  const nextLink = `/${module.name}/${nextPath}`

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const { state, dispatch } = useContext(AppContext)

  let doctorEnrollmentValues = {
    ...state.doctorEnrollment,
    wouldLikeToReceiveUpdates: state.wouldLikeToReceiveUpdates,
  }
  let patientEnrollmentValues = {
    ...state.patientEnrollment,
    documents: state?.documents,
    receivePromotionalCommunications: state?.receivePromotionalCommunications,
  }

  let initialValues =
    module.name === "doctor" ? doctorEnrollmentValues : patientEnrollmentValues

  const layoutSubtitle = (
    <span>
      Please review if the following information is correct.
      <br className="is-hidden-mobile" /> {module.subtitle}
    </span>
  )

  const handleSuccessCallback = () => {
    setLoading(false)
  }

  const handleSubmitError = ({ message }) => {
    setErrorMessage(message)
    setLoading(false)
  }

  useEffect(() => {
    if (
      sessionStorage.getItem(GASTBY_DOCTOR_ENCRYPTED_KEY) !==
      GATSBY_DOCTOR_ENCRYPTED_VALUE
    )
      navigate(`/${module.name}/${backPath}`)
  }, [backPath, module.name])

  const handleSubmit = () => {
    setLoading(true)
    handleUserEnroll({
      type:
        module.name === "doctor" ? "doctor_enrollment" : "patient_enrollment",
      enrollmentData: initialValues,
      documents: state.documents,
      callback: () => {
        handleSuccessCallback()
        dispatch({
          type: RESET_STATE,
        })
        navigate(nextLink)
      },
      errorCallback: handleSubmitError,
    })
  }
  return (
    <Layout
      title={module.title}
      seoTitle={module.seoTitle}
      subtitle={layoutSubtitle}
    >
      <Container isCentered>
        {summaryConfig.map(section => {
          let hasSummaryData = false
          return (
            <Section
              title={section.section}
              isRequired
              addOns={{
                right: <EditDetailsButton route={`${section.route}`} />,
              }}
            >
              <div className="table-container mb-3">
                <table class="table is-fullwidth is-size-5">
                  <tbody>
                    {section.fields.map(field => {
                      if (
                        !defaultValues.includes(
                          parseSummaryValue(
                            get(initialValues, field.key),
                            field.type
                          )
                        )
                      ) {
                        hasSummaryData = true

                        if (field.key === "otherSpecialization") {
                          let specialization = parseSummaryValue(
                            get(initialValues, "specialization.value"),
                            "text"
                          )

                          if (specialization !== "Other (please specify)")
                            return null
                        }

                        if (field.key === "otherDepartment") {
                          let department = parseSummaryValue(
                            get(initialValues, "department.value"),
                            "text"
                          )

                          if (department !== "Other (please specify)")
                            return null
                        }

                        return (
                          <tr>
                            <td className="has-text-weight-bold">
                              {field.label}
                            </td>
                            <td
                              className={classNames(
                                styles["summary__tableData"]
                              )}
                            >
                              {parseSummaryValue(
                                get(initialValues, field.key),
                                field.type
                              )}
                            </td>
                          </tr>
                        )
                      }
                      return null
                    })}
                    {!hasSummaryData && (
                      <Message color="warning">
                        No data found for {section.section.toLowerCase()}{" "}
                        section.
                      </Message>
                    )}
                  </tbody>
                </table>
              </div>
            </Section>
          )
        })}
        {state?.documents?.length > 0 && (
          <Section
            title="Uploaded Documents"
            isRequired
            addOns={{
              right: (
                <EditDetailsButton
                  route={`/patient/enroll/#prescription-uploads`}
                />
              ),
            }}
          >
            <PrescriptionDetails />
          </Section>
        )}
        <FormAddons module={`${module.name}-summary`} />
        {errorMessage && <Message color="danger">{errorMessage}</Message>}
        <ActionButtons
          back={{
            label: "Back",
            callback: () => {
              navigate(`/${module.name}/${backPath}`)
            },
          }}
          submit={{
            label: "Submit",
            onClick: handleSubmit,
            loading,
          }}
        />
      </Container>
    </Layout>
  )
}

export default Summary
