export const packages = [
  {
    hospital: "Philippine Heart Center (PHC)",
    tests: ["HbA1c", "Lipid Profile", "SGPT", "Creatinine"],
    partnerLaboratories: [
      {
        value: "Philippine Heart Center (PHC)",
        label: "Philippine Heart Center (PHC)",
      },
      {
        value: "Hi-Precision Diagnostics Centre",
        label: "Hi-Precision Diagnostics Centre",
      },
    ],
    laboratories: [
      {
        tests: ["HbA1c", "Lipid Profile", "SGPT", "Creatinine"],
        amount: "1,250.00",
        name: "Philippine Heart Center (PHC)",
        logo: "phcLogo",
        description: `
        <p class="mb-1"><b>Philippine Heart Center</b></p>
        <p class="is-size-6 mb-0"><b>Address:</b> East Avenue, Diliman, Quezon City, Metro Manila</p>
        <p class="is-size-6 mb-0"><b>Operating hours:</b> Mondays to Sundays | 24-hour operations</p>`,
        rawText: `Philippine Heart Center (PHC)\nAddress: East Avenue, Diliman, Quezon City, Metro Manila\nOperating hours: Mondays to Sundays | 24-hour operations`,
      },
      {
        tests: ["HbA1c", "Lipid Profile", "SGPT", "Creatinine"],
        amount: "1,250.00",
        name: "Hi-Precision Diagnostics Centre",
        logo: "hpLogo",
        description: `
        <p class="mb-1"><b>Hi-Precision Diagnostics Centre</b></p>
        <p class="is-size-6 mb-0">You may claim your diagnostic support at Hi-Precision clinics in Luzon and Mindanao. View Hi-Precision branches and operating hours <a href="https://www.hi-precision.com.ph/branch.do" target="_blank" class="has-text-weight-bold has-text-primary">here</a>.</p>`,
        rawText: ``,
      },
    ],
  },
  {
    hospital: "Lung Center of the Philippines",
    tests: ["Spirometry", "RT-PCR (prerequisite for Spirometry)"],
    partnerLaboratories: [
      {
        value: "Lung Center of The Philippines",
        label: "Lung Center of The Philippines",
      },
    ],
    laboratories: [
      {
        tests: ["HbA1c", "Lipid Profile", "SGPT", "Creatinine"],
        amount: "1,000.00",
        name: "Lung Center of The Philippines",
        logo: "lcpLogo",
        description: `
        <p class="mb-1"><b>Lung Center of The Philippines</b></p>
        <p class="is-size-6 mb-0"><b>Address:</b> Quezon Avenue, Diliman, Quezon City, Metro Manila</p>
        <p class="is-size-6 mb-0"><b>Operating hours:</b> TBC</p>`,
        rawText: `Lung Center of The Philippines\nAddress: Quezon Avenue, Diliman, Quezon City, Metro Manila\nOperating hours: TBC`,
      },
    ],
  },
]
